import { PartnerType } from './partner-type.model';

export class Partner {
  id: string;

  eventId: string;

  type: PartnerType;

  name: string;

  logoWeb: string;

  logoMobile: string;

  priority: number;
}
